import { JsonApiService } from '@app/core/services/json-api.service';
import { Injectable, OnInit } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of, BehaviorSubject } from 'rxjs/index';
import { map, take, tap } from 'rxjs/operators';

import * as fromUser from '@app/core/store/user';
import { UserService, NotificationService } from "@app/core/services";
import { User } from '../models';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanLoad {

    loginStatus$: Observable<boolean>;
    currentUser$: Observable<User>;
    currentUser: any;

    constructor(
        private store: Store<fromUser.UserState>,
        private userService: UserService,
        private router: Router,
        private jsonApiService: JsonApiService,
        private notificationService: NotificationService) {
        this.loginStatus$ = store.select(fromUser.getIsLogin);
        this.currentUser$ = store.select(fromUser.getCurrentUser);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let url: string = state.url;
        // console.log(url)
        return this.checkLogin(url);
    }

    canLoad(route: Route): Observable<boolean> {
        let url = `/${route.path}`;
        // console.log(url)
        return this.checkLogin(url);
    }


    checkLogin(url: string): Observable<boolean> {
        return this.loginStatus$.pipe(
            tap(async (status) => {

                if (!status) {
                    this.router.navigate(['Web/Login']);
                }

                this.currentUser$.subscribe(v => {
                    this.currentUser = v;
                });

                if (this.currentUser) {
                    const permission = this.currentUser.Permission;
                    const parts = url.split('/');
                    const answer = parts[parts.length - 1];
                    // console.log(permission, parts , answer);
                    // return true;
                    if (permission == null) {
                        // console.log('請求失敗');
                        this.router.navigate(['/Bulletin/BulletinBoard']);
                        return false;

                    } else if (permission && permission.indexOf(answer) === -1) {

                        this.router.navigate(['/Bulletin/BulletinBoard']);
                        return false;
                    } else {
                        return true;
                    }
                }

            }),
            take(1),
        );
    }


}