import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Billboard } from '@app/core/models';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { config } from '@app/core/app.config';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditManagementService {
  constructor(private httpService: HttpClientService) { }

  public getLogAudit(dataSrc: any): Observable<any> {
    return this.httpService.httpPost(
      `${config.LogAuditUrl}/GetLogAudit`,
      dataSrc
    );
  }

  public getLogAuditDetails(dataSrc: any): Observable<any> {
    return this.httpService.httpPost(
      `${config.LogAuditUrl}/GetLogAuditDetails`,
      dataSrc
    );
  }
}
