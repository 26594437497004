import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'sa-nia-layout',
    templateUrl: './nia-layout.component.html',
    styles: [],
})
export class NIALayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}