import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
	NotificationService,
	UserService,
	JsonApiService,
} from '@app/core/services';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { User } from '@app/core/models';
import { BehaviorSubject } from 'rxjs/Rx';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

import { ConfirmationService } from 'primeng/api';

@Component({
	selector: 'sa-logout',
	template: `
		<div
			*ngIf="currentUser"
			id="logout"
			(click)="showPopup()"
			class="btn-header transparent pull-right"
		>
			<span>
				<a title="Sign Out" style="font-size: 22px;">
					<em class="fa fa-sign-out"></em>
				</a>
			</span>
		</div>
	`,
	styles: [],
})
export class LogoutComponent implements OnInit {
	currentUser$: Observable<User>;
	currentUser: User;

	public user;

	constructor(
		private router: Router,
		private store: Store<fromUser.UserState>,
		private notificationService: NotificationService,
		private userService: UserService,
		private jsonApiService: JsonApiService,
		private confirmationService: ConfirmationService
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
	}

	ngOnInit() {
		this.currentUser$.subscribe((v) => {
			this.currentUser = v;
		});
	}

	showPopup() {
		this.confirmationService.confirm({
			key: 'logOut',
			header: `是否要登出`,
			icon: 'fa fa-sign-out txt-color-teal',
			message: `是否要登出 <span class='txt-color-teal'><strong>${this.currentUser.UserName}</strong></span> ?`,
			accept: () => {
				this.logout();
			},
			reject: () => { },
		});
	}

	logout() {
		// let input = {
		//   U_ID: this.currentUser.UserId,
		// };
		let input = {};
		let user: User = { ...input };
		// console.log(user);
		this.store.dispatch(new fromUser.LogoutAction(user));
	}
}
