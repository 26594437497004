export const environment = {
	production: true,

	/** INFO:坤侑測試機 */
	asrUrl: 'test-transkribera-payment-in-lab4.yating.tw',
	baseUrl: 'AsrApi/',
	downloadUrl: 'AsrApi',
	filePath: './',
	firebase: {},

	AsrModelVersion_zhen: 'O2.140.1.0', //國英模型版號
	AsrModelVersion_zhtw: 'O2.1200.0',//國臺模型版號

	debug: false,
	log: {
		auth: false,
		store: false,
	},

	smartadmin: {
		api: null,
		db: 'smartadmin-angular',
	},
};
