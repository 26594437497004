import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';

@Injectable({
	providedIn: 'root'
})
export class AuthorityManagementService {
	constructor(private httpService: HttpClientService) { }

	public getUserInfoList(dataSrc: any): Observable<any> {
		return this.httpService.httpGet(
			`${config.UserInfoUrl}/GetUserInfoList`
		);
	}

	// 新增人員
	public createUserInfo(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/CreateUserInfo`,
			dataSrc
		);
	}

	// 更新人員
	public updateUserInfo(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UpdateUserInfo`,
			dataSrc
		);
	}

	// 刪除人員
	public deleteUser(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/DeleteUser`,
			dataSrc
		);
	}

	// 取得群組權限列表
	public getRoleGroupList(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.RoleGroupUrl}/GetRoleGroupList`,
			dataSrc
		);
	}

	// 更新雅婷權限
	public updateYatingRoleGroup(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.RoleGroupUrl}/UpdateYatingRoleGroup`,
			dataSrc
		);
	}

	/** 匯入使用者資訊 */
	public importUserInfo(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/ImportUserInfo`,
			dataSrc
		);
	}
}
