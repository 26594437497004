import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
/*-- Prime NG --*/
import { SelectItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
@Component({
  selector: 'confirm-dialog-with-textarea',
  templateUrl: './confirm-dialog-with-textarea.component.html',
  styleUrls: ['./confirm-dialog-with-textarea.component.scss'],
})
export class ConfirmDialogWithTextareaComponent implements OnInit {
  data;
  /** 標題 */
  title: string;
  /** 確認訊息 */
  confirmMessage: string;
  /** 是否需要輸入欄 */
  needTextarea: boolean;
  /** 提示文字 */
  confirmPlaceholder: string;
  /** 表單 */
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig // public dialogRef: MatDialogRef<ConfirmDialogWithTextareaComponent>, // @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {
    // INFO:PrimeNg 傳入的資料 data , 以 DynamicDialogConfig 來取值
    // INFO:PrimeNg ref 來關閉跳窗
    this.data = this.config.data;
    console.log('Dialog確認取消OnInit資料:', this.data);
    // this.initForm();
    // console.log('this.config.data:', this.config.data);
  }
  editFormClose(buttonName) {
    if (buttonName === 'confirm') {
      // 確認
    }
    if (buttonName === 'cancel') {
      // 取消
    }
    if (buttonName === undefined) {
      // 叉叉
    }
  }
  initForm() {
    this.form = this.formBuilder.group({
      confirmNote: [''],
    });
    this.confirmMessage = this.data.confirmMessage;
    this.confirmPlaceholder = this.data.confirmPlaceholder;
    this.needTextarea = this.data.needTextarea;
  }
  // 取消
  onCancelClick() {
    const param = {
      confirm: false,
      confirmNote: this.needTextarea ? this.form.value.confirmNote : '',
    };
    this.ref.close(param);
  }
  // 確認
  onConfirmClick() {
    console.log('this.form:', this.form);
    const param = {
      confirm: true,
      confirmNote: this.needTextarea ? this.form.value.confirmNote : '',
    };
    this.ref.close(param);
  }
}