import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Billboard } from '@app/core/models';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { config } from '@app/core/app.config';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemoCalendarService {
  constructor(private httpService: HttpClientService) { }

  // 新增任務行事曆
  public createMeet(dataSrc: any): Observable<any> {
    return this.httpService.httpPost(
      `${config.MeetUrl}/CreateMeet`,
      dataSrc
    );
  }

  // 修改任務行事曆
  public updateMeet(dataSrc: any): Observable<any> {
    return this.httpService.httpPost(
      `${config.MeetUrl}/UpdateMeet`,
      dataSrc
    );
  }

  // 刪除任務行事曆
  public deleteMeet(dataSrc: any): Observable<any> {
    return this.httpService.httpPost(
      `${config.MeetUrl}/DeleteMeet`,
      dataSrc
    );
  }

  // 取得所有備忘行事曆(可以藉由會議類型)
  public getMeetForCalendar(dataSrc: any): Observable<any> {
    return this.httpService.httpPost(
      `${config.MeetUrl}/GetMeetForCalendar`,
      dataSrc
    );
  }

  // 取得某天有幾場會議
  public getMeetCountForDay(dataSrc: any): Observable<any> {
    return this.httpService.httpPost(
      `${config.MeetUrl}/GetMeetCountForDay`,
      dataSrc
    );
  }

  // 取得備忘行事曆內容
  public getMeetList(dataSrc: any): Observable<any> {
    return this.httpService.httpPost(
      `${config.MeetUrl}/GetMeetList`,
      dataSrc
    );
  }

  private getBaseUrl() {
    return environment.production
      ? document.getElementsByTagName('base')[0].href
      : '';
  }
}
