import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SyscodeService, MessageService, UserService } from '@app/core/services';
import { Observable } from 'rxjs/Observable';
import { User } from '@app/core/models';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

import { CountdownComponent } from 'ngx-countdown';

// PrimeNg
import { ConfirmationService } from 'primeng/api';
// SignalR
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

declare var $: any;

@Component({
	selector: 'sa-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	currentUser$: Observable<User>;
	currentUser: User;

	@ViewChild('countdown', { static: true }) countdown: CountdownComponent;

	// INFO:正式用
	leftTime: number = 1200; // 倒數時間(秒) 20分鐘
	notify: number = 300; // 警示秒數:reLogin() 5分鐘
	checkPositionTime: number = 1; // 每1分鐘 檢測鼠標歷史位置 與 現位置 差異

	// INFO:測式用
	// leftTime: number = 30; // 倒數時間(秒) 測試用30秒
	// notify: number = 15; //  警示秒數:reLogin() 測試用15秒
	// checkPositionTime: number = 0.1; // 檢測鼠標歷史位置 測試用 10秒

	countdownConfig = {
		leftTime: this.leftTime,
		notify: [this.notify],
		format: '登出倒數：m分s秒',
		demand: false,
	};

	// 在線人數
	onlineUserCnt = 0;

	// 滑鼠座標
	mXCurrent: number = 0;
	mYCurrent: number = 0;
	mXBefore: number = 0;
	mYBefore: number = 0;

	// SignalR 連線
	hubConnection: HubConnection;

	loginKey;

	constructor(
		private router: Router,
		private store: Store<fromUser.UserState>,
		private syscodeService: SyscodeService,
		private userService: UserService,
		private confirmationService: ConfirmationService,
		private messageService: MessageService
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
		this.initWebSocket();
	}

	ngOnInit() {
		this.currentUser$.subscribe((v) => {
			this.currentUser = v;
			this.loginKey = v.LoginKey;
		});

		setInterval(() => {
			this.updateUserLogin();
		}, 10 * 1000);

		this.syscodeService.initSyscode();
		// this.countdown.begin();

		// setInterval(() => {
		// 	// 每1分鐘 檢測是否閒置
		// 	this.checkIdle();
		// }, this.checkPositionTime * 60 * 1000);
	}

	// 更新在線資訊
	updateUserLogin() {
		this.userService
			.updateUserLogin({
				U_ID: this.currentUser.UserId
			})
			.subscribe();
	}

	// 檢測是否閒置
	checkIdle() {
		// 如果滑鼠位置相同 代表閒置
		if (
			this.mXBefore === this.mXCurrent &&
			this.mYBefore === this.mYCurrent
		) {
			this.start();
		}
		this.mXBefore = this.mXCurrent;
		this.mYBefore = this.mYCurrent;
	}

	// 初始化 SignalR 並連線
	initWebSocket() {
		// 設定位置
		this.hubConnection = new HubConnectionBuilder()
			.withUrl('/AsrApi/onlinecount')
			.build();

		// 建立method, updateCount 取得回傳
		this.hubConnection.on('updateCount', (res) => {
			console.log('SignalR 連線 res:', res);
			this.onlineUserCnt = res;
		});

		// 開始連線
		this.hubConnection
			.start()
			.then((res) => {
				// console.log('SignalR 連線Connnected Success', res);
				// method, LoginUserId 傳送ID給後端
				// this.hubConnection.invoke('LoginUserId', this.currentUser.UserId);
			})
			.catch((err) => console.log(`SignalR Error : ${err}`));
	}

	// SignalR 重新連線
	startSignalR() {
		this.hubConnection
			.start()
			.then((res) => {
				this.hubConnection.invoke('SendUserId', this.currentUser.UserId);
			})
			.catch((err) => console.log(`SignalR Error : ${err}`));
	}

	// SignalR 停止
	stopSignalR() {
		this.hubConnection.stop();
	}

	locatePosition(e) {
		return;
		// 標記滑鼠位置
		this.mXCurrent = e.clientX;
		this.mYCurrent = e.clientY;
		this.pause();
	}

	start() {
		this.countdown.begin();
	}

	pause() {
		if (this.countdown.left < 1200000) {
			this.countdown.restart(); // 重新倒數
			this.countdown.pause();
			return;
		}
		this.countdown.pause();
	}

	gotoUserManagement() {
		this.router.navigate(['/System/UserManagement']);
	}

	handleEvent(e: Event) {
		switch (e['action']) {
			case 'notify':
				this.reLogin();
				break;
			case 'done':
				this.logout();
				break;
		}
	}

	reLogin() {
		this.confirmationService.confirm({
			key: 'logOut',
			header: `是否要登出`,
			icon: 'fa fa-sign-out txt-color-teal',
			message: `登出倒數即將結束，系統將在${this.notify / 60
				}分鐘後自動登出`,
			accept: () => {
				this.messageService.add({
					severity: 'success',
					summary: '請繼續使用',
					detail: '系統重新倒數',
					life: 3000,
				});
				this.countdown.restart(); // 重新倒數
			},
			reject: () => {
				this.messageService.add({
					severity: 'warn',
					summary: '時間到 已登出',
					detail: '若要繼續使用請重新登入...',
					life: 3000,
				});
			},
		});
	}

	logout() {
		let input = {
			UserId: this.currentUser.UserId,
		};
		let user: User = { ...input };
		this.store.dispatch(new fromUser.LogoutAction(user));
	}

	// 是否要登出
	showPopup() {
		this.confirmationService.confirm({
			key: 'logOut',
			header: `是否要登出`,
			icon: 'fa fa-sign-out txt-color-teal',
			message: `是否要登出 <span class='txt-color-teal'><strong>${this.currentUser.UserName}</strong></span> ?`,
			accept: () => {
				this.logout();
			}
		});
	}

	onToggle() {
		var $body = $('body');
		var documentMethods = {
			enter: [
				'requestFullscreen',
				'mozRequestFullScreen',
				'webkitRequestFullscreen',
				'msRequestFullscreen',
			],
			exit: [
				'cancelFullScreen',
				'mozCancelFullScreen',
				'webkitCancelFullScreen',
				'msCancelFullScreen',
			],
		};

		if (!$body.hasClass('full-screen')) {
			$body.addClass('full-screen');
			document.documentElement[
				documentMethods.enter.filter((method) => {
					return document.documentElement[method];
				})[0]
			]();
		} else {
			$body.removeClass('full-screen');
			document[
				documentMethods.exit.filter((method) => {
					return document[method];
				})[0]
			]();
		}
	}
}
