import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Billboard } from '@app/core/models';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class BillboardService {
	constructor(private httpService: HttpClientService, private http: HttpClient) { }

	// 新增系統公告
	public createBillboard(dataSrc: Billboard): Observable<any> {
		return this.httpService.httpPost(
			`${config.BillboardUrl}/CreateBillboard`,
			dataSrc
		);
	}

	// 修改系統公告
	public updateBillboard(dataSrc: Billboard): Observable<any> {
		return this.httpService.httpPost(
			`${config.BillboardUrl}/UpdateBillboard`,
			dataSrc
		);
	}

	// 刪除系統公告
	public deleteBillboard(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.BillboardUrl}/DeleteBulletins`,
			dataSrc
		);
	}

	// 取得系統公告
	public getBillboard(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.BillboardUrl}/GetBulletinsList`,
			dataSrc
		);
	}

	// 刪除系統公告附檔
	public deleteFile(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.BillboardUrl}/DeleteFile`,
			dataSrc
		);
	}

	/** 下載系統公告所有附件 */
	public downloadBulletinAllAttach(dataSrc: any): Observable<any> {
		return this.http.post(
			`${config.BillboardUrl}/DownloadBulletinAllAttach`,
			dataSrc, {
			responseType: 'arraybuffer',
		})
	}

	private getBaseUrl() {
		return environment.production
			? document.getElementsByTagName('base')[0].href
			: '';
	}
}
