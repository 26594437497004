import { environment } from '../../environments/environment';
const baseUrl: string = environment.baseUrl + 'api/';

export const config: any = {
	baseUrl: baseUrl,

	/** 使用者 */
	UserInfoUrl: baseUrl + 'UserInfo',
	/** 權限角色 (new) */
	RoleGroupUrl: baseUrl + 'RoleGroup',
	/** 系統代碼 (new) */
	SysCodeUrl: baseUrl + 'SysCode',
	/** 圖形驗證 (new) */
	AuthorizationUrl: baseUrl + 'Authorization',
	/** 問題回報 (new) */
	ProblemReportUrl: baseUrl + 'ProblemReport',
	/** 公告 (new) */
	BillboardUrl: baseUrl + 'Bulletins',
	/** 任務行事曆 (new) */
	MeetUrl: baseUrl + 'Meet',
	/** 逐字稿管理 (new) */
	TranscriptUrl: baseUrl + 'Transcript',
	/** 文件饋入預訓練 (new) */
	LanguageModelUrl: baseUrl + 'LanguageModel',
	/** 稽核管理 (new) */
	LogAuditUrl: baseUrl + 'LogAudit',
	/** 即編功能 (new) */
	EditInTimeUrl: baseUrl + 'EditInTime',
	/** 單登 (new) */
	SingleUrl: baseUrl + 'Single',
	/** 版本 (new) */
	VersionUrl: baseUrl + 'Version',


	logoutUrl: baseUrl + 'auth/logout',
	reLoadSessionUrl: baseUrl + 'auth/reLoadSession',
	versionUrl: baseUrl + 'auth/version',
};
