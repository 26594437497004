import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { NIALayoutComponent } from '@app/shared/layout/app-layouts/nia-layout.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { UrlGuard } from '@app/core/guards/url.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'Web/Login',
		pathMatch: 'full',
	},
	{
		path: '',
		canActivate: [AuthGuard],
		canLoad: [AuthGuard],
		canActivateChild: [UrlGuard],
		component: MainLayoutComponent,
		data: { pageTitle: 'Home' },
		children: [
			// 新router -------------------------------
			{
				path: 'Home',
				loadChildren: () =>
					import('./features/home/home.module').then(
						(m) => m.HomeModule
					),
				data: {
					pageTitle: 'Home',
				},
			},
			{
				path: 'Bulletin',
				loadChildren: () =>
					import('./features/bulletin/bulletin.module').then(
						(m) => m.BulletinModule
					),
				data: {
					pageTitle: 'Bulletin',
				},
			},
			{
				path: 'TaskCalendar',
				loadChildren: () =>
					import('./features/task-calendar/task-calendar.module').then(
						(m) => m.TaskCalendarModule
					),
				data: {
					pageTitle: 'TaskCalendar',
				},
			},
			{
				path: 'TranscriptManagement',
				loadChildren: () =>
					import('./features/transcript-management/transcript-management.module').then(
						(m) => m.TranscriptManagementModule
					),
				data: {
					pageTitle: 'TranscriptManagement',
				},
			},
			// 舊router -------------------------------
			{
				path: 'System',
				loadChildren: () =>
					import('./features/system/system.module').then(
						(m) => m.SystemModule
					),
				data: {
					pageTitle: 'System',
				},
			},
		],
	},
	{
		path: 'Web',
		component: NIALayoutComponent,
		loadChildren: () =>
			import('./features/web/web.module').then((m) => m.WebModule),
	},
	// { path: "**", redirectTo: "Administrator/Notice" }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule { }
