import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sysCodeFilter'
})
export class SysCodePipe implements PipeTransform {

  transform(value: string, exponent: String[]): string {
    // console.log(value , exponent)
    let data: String[] = exponent.filter((item: String) => item['value'] == value);
    return data.length > 0 ? data[0]['label'] : "";
  }
}
