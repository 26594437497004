import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { config } from '@app/core/app.config';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ProblemReportService {

	constructor(private httpService: HttpClientService) { }

	/** 新增問題回報 */
	createUserReport(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.ProblemReportUrl}/CreateUserReport`,
			dataSrc
		);
	}

	/** 取得問題回報列表 */
	getProblemReportList(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.ProblemReportUrl}/GetProblemReportList`,
			dataSrc
		);
	}

	/** 回覆問題回報列表 */
	replyReport(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.ProblemReportUrl}/ReplyReport`,
			dataSrc
		);
	}
}