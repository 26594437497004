import { JsonApiService } from './json-api.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { config } from '@app/core/app.config';
import { environment } from '../../../environments/environment';
import { SelectItem } from 'primeng/api';

@Injectable({
	providedIn: 'root',
})
export class SyscodeService {
	constructor(
		private httpService: HttpClientService,
		private jsonApiService: JsonApiService
	) { }

	/** 問題類型 */
	ProblemType$ = new BehaviorSubject<SelectItem[]>(null);
	/** 問題狀態 */
	ProcessStatus$ = new BehaviorSubject<SelectItem[]>(null);
	/** 會議類型 */
	MeetType$ = new BehaviorSubject<SelectItem[]>(null);
	/** 機關單位 */
	Org$ = new BehaviorSubject<SelectItem[]>(null);
	/** 逐字稿狀態 */
	TranscriptState$ = new BehaviorSubject<SelectItem[]>(null);
	/** 公告類型 */
	BulletinType$ = new BehaviorSubject<SelectItem[]>(null);

	/** true/false */
	OnlySelf$ = new BehaviorSubject<SelectItem[]>(null);
	/** 1/0 */
	OneTwo$ = new BehaviorSubject<SelectItem[]>(null);

	initSyscode() {
		this.getProblemtypeSysCode();
		this.getMeetTypeSysCode();
		this.getOrgSysCode();
		this.getTranscriptStateSysCode();
		this.getProcessStatusSyscode();
		this.getBulletinTypeSysCode();
		this.getOnlySelfSysCode();
	}

	/** 取得問題回報類型 Syscode */
	getProblemtypeSysCode() {
		this.getSysCodeByType({ Type: 'problemtype' }).subscribe((res) =>
			this.ProblemType$.next(res.body)
		);
	}
	/** 取得問題回報處理類型 Syscode */
	getProcessStatusSyscode() {
		this.getSysCodeByType({ Type: 'processstatus' }).subscribe((res) =>
			this.ProcessStatus$.next(res.body)
		);
	}
	/** 取得案件類型 Syscode */
	getMeetTypeSysCode() {
		this.getSysCodeByType({ Type: 'MeetType' }).subscribe((res) =>
			this.MeetType$.next(res.body)
		);
	}
	/** 取得機關單位 Syscode */
	getOrgSysCode() {
		this.getSysCodeByType({ Type: 'Org' }).subscribe((res) =>
			this.Org$.next(res.body)
		);
	}
	/** 取得逐字稿狀態 Syscode */
	getTranscriptStateSysCode() {
		this.getSysCodeByType({ Type: 'TranscriptState' }).subscribe((res) =>
			this.TranscriptState$.next(res.body)
		);
	}
	/** 取得公告類型 Syscode */
	getBulletinTypeSysCode() {
		this.getSysCodeByType({ Type: 'BulletinType' }).subscribe((res) =>
			this.BulletinType$.next(res.body)
		);
	}


	/** 取得true/false Syscode */
	getOnlySelfSysCode() {
		this.jsonApiService
			.fetch('/syscode/truefalse.json')
			.subscribe((res) => {
				this.OnlySelf$.next(res);
			});
	}
	/** 取得1/0 Syscode */
	getOneTwoSysCode() {
		this.jsonApiService.fetch('/syscode/onetwo.json').subscribe((res) => {
			this.OneTwo$.next(res);
		});
	}


	/** 取得單位代碼類型 */
	public getSysType(): Observable<any> {
		return this.httpService.httpPost(`${config.SysCodeUrl}/GetSysType`, '');
	}
	/** 取得單層系統代碼 */
	getSysCodeByType(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.SysCodeUrl}/GetSysCodeByType`,
			dataSrc
		);
	}
	/** 取得單位代碼 */
	getSysCode(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.SysCodeUrl}/GetSysCode`,
			dataSrc
		);
	}
	private getBaseUrl() {
		return environment.production
			? document.getElementsByTagName('base')[0].href
			: '';
	}

	/** 新增代碼 */
	public createSysCode(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.SysCodeUrl}/CreateSysCode`,
			dataSrc
		);
	}
	/** 編輯、作廢代碼 */
	public editSysCode(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.SysCodeUrl}/EditSysCode`,
			dataSrc
		);
	}
}
