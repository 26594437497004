import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';


@Injectable({
	providedIn: 'root'
})
export class VersionService {

	constructor(private httpService: HttpClientService) { }

	// 更新版更紀錄
	public updateVersionInfo(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.VersionUrl}/UpdateVersionInfo`,
			dataSrc
		);
	}

	// 取得版更紀錄
	public getVersionInfo(): Observable<any> {
		return this.httpService.httpPost(
			`${config.VersionUrl}/GetVersionInfo`,
			''
		);
	}
}
