import { JsonApiService } from './../../services/json-api.service';
import {
	UserService,
	MessageService,
} from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Action } from '@ngrx/store';
import * as actions from './user.actions';
import { User, ResponseObj } from '@app/core/models';
import {
	map,
	switchMap,
	filter,
	catchError,
	tap,
	exhaustMap,
} from 'rxjs/internal/operators';
import { of } from 'rxjs/observable/of';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

@Injectable()
export class UserEffects {
	constructor(
		private action$: Actions,
		private router: Router,
		private userService: UserService,
		private jsonApiService: JsonApiService,
		private messageService: MessageService
	) { }

	@Effect()
	login$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.Login),
		// map((action: actions.LoginAction) => action.payload),
		exhaustMap((action: actions.LoginAction) => {
			// switchMap((action: actions.LoginAction) => {
			// return this.jsonApiService.fetch("/user/login-info.json").pipe(
			return this.userService.userLogin(action.payload).pipe(
				map((res: ResponseObj) => {
					if (res.code == '000') {
						return new actions.LoginSuccessAction(res.body);
					} else if (res.code == '1002' || res.code == '124') { //使用預設密碼登入 || 密碼使用超過3個月
						return new actions.LoginFailAction(res);
					} else {
						let content = '';
						switch (res.code) {
							case '117':
								content = '驗證碼不符，請確認驗證碼！';
								break;
							case '119':
								content = '請確認使用者『帳號』與『密碼』是否正確，錯誤逾5次，系統將自動鎖定15分鐘。';
								break;
							case '125':
								content = '查無該帳號，請確認輸入的帳號是否正確！';
							case '123':
								this.getRepeatLoginInfo(action.payload);
								break;
							case '126':
								content = '帳號密碼輸入錯誤，已逾上限5次，系統已自動鎖定15分鐘，敬請稍後再試。';
								break;
						}
						if (res.code != '123') {
							this.messageService.add({
								severity: 'error',
								summary: '登入失敗',
								detail: `${content}`,
								life: 3000,
							});
						}
						return new actions.LoginFailAction(res);
					}
				}),
				catchError((err) => of(new actions.LoginFailAction(err)))
			);
		})
	);

	@Effect({ dispatch: false })
	loginSuccess$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LoginSuccess),
		tap((user: actions.LoginSuccessAction) => {
			this.messageService.add({
				severity: 'success',
				summary: '登入成功',
				detail: '歡迎使用司法會議中文語音辨識系統',
				life: 3000,
			});
			setTimeout(() => {
				window.location.href = `https://${environment.asrUrl}/judicial/api/v1/user/enterprise/login?token=${user.payload.Token}&redirect=http://localhost:4200/#/Home/Entrance`
			}, 1000);
		})
	);

	@Effect({ dispatch: false })
	loginFail$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LoginFail),
		tap((user) => {
			// console.log(user);
			// this.messageService.add({
			// 	severity: 'error',
			// 	summary: '登入失敗',
			// 	detail: '請確認 使用者名稱 及 密碼 是否正確',
			// 	life: 3000,
			// });
		})
	);

	@Effect()
	logout$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.Logout),
		exhaustMap((action: actions.LogoutAction) => {
			// switchMap((action: actions.LoginAction) => {
			// return this.jsonApiService.fetch('/user/login-info.json').pipe(
			return this.userService.userLogout(action.payload).pipe(
				map((res: ResponseObj) => {
					if (res.code === '000') {
						return new actions.LogoutSuccessAction();
					} else {
						return new actions.LogoutFailAction(res.body[0]);
					}
				}),
				catchError((err) => of(new actions.LogoutSuccessAction()))
			);
		})
	);

	@Effect({ dispatch: false })
	logoutSuccess$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LogoutSuccess),
		tap((user: actions.LogoutSuccessAction) => {
			this.router.navigateByUrl('/Web/Login');
			setTimeout(() => {
				window.location.reload();
			}, 300)
		})
	);

	@Effect({ dispatch: false })
	logoutFail$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LogoutFail),
		tap((action: actions.LogoutFailAction) => {
			this.router.navigateByUrl('/Web/Login');
		})
	);

	// @Effect()
	// updateUser$: Observable<Action> = this.action$.pipe(
	//   ofType(actions.UserActionTypes.UpdateUser),
	//   // map((action: actions.LoginAction) => action.payload),
	//   exhaustMap((action: actions.LoginAction) => {
	//     // switchMap((action: actions.LoginAction) => {
	//     return this.userService.userLogin(action.payload).pipe(
	//       map((res: User) => {
	//         if (res.ID) {
	//           // console.log(res.UserId)
	//           return new actions.UpdateUserSuccessAction(res);
	//         } else {
	//           return new actions.UpdateUserFailAction();
	//         }
	//       }),
	//       catchError((err) => of(new actions.UpdateUserFailAction()))
	//     );
	//   })
	// );

	@Effect()
	singleSignOn$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.SingleSignOn),
		exhaustMap((action: actions.SingleSignOnAction) => {
			return this.userService.singleSignOn(action.payload).pipe(
				map((res: ResponseObj) => {
					if (res.isSuccess) {
						return new actions.SingleSignOnSuccessAction(res.body);
					} else {
						return new actions.SingleSignOnFailAction();
					}
				}),
				catchError((err) => of(new actions.SingleSignOnFailAction()))
			);
		})
	);

	@Effect({ dispatch: false })
	singleSignOnSuccess$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.SingleSignOnSuccess),
		tap((user: actions.SingleSignOnSuccessAction) => {
			console.log(user);
			this.messageService.add({
				severity: 'success',
				summary: '登入成功',
				detail: '歡迎使用司法會議中文語音辨識系統',
				life: 3000,
			});
			setTimeout(() => {
				window.location.href = `https://${environment.asrUrl}/judicial/api/v1/user/enterprise/login?token=${user.payload.Token}&redirect=http://localhost:4200/#/Home/Entrance`
			}, 1000);
		})
	);

	// for ap
	@Effect({ dispatch: false })
	singleSignOnFail$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.SingleSignOnFail),
		tap((user: actions.SingleSignOnFailAction) => {
			this.messageService.add({
				severity: 'error',
				summary: '登入失敗',
				detail: '請確認 使用者名稱 及 密碼 是否正確',
				life: 3000,
			});
			// this.router.navigateByUrl('/Web/Login');
			// setTimeout(() => {
			// 	window.location.reload();
			// }, 2000);
		})
	);

	// for sso
	// @Effect({ dispatch: false })
	// singleSignOnFail$: Observable<Action> = this.action$.pipe(
	//     ofType(actions.UserActionTypes.SingleSignOnFail),
	//     tap((user: actions.SingleSignOnFailAction) => {
	//         console.log(user)
	//         this.router.navigateByUrl('/Miscellaneous/ErrorPage');
	//     })
	// );

	getRepeatLoginInfo(data) {
		this.userService.checkUserLogin({ U_ID: data.U_ID })
			.pipe(
				tap((res) => {
					this.messageService.add({
						severity: 'error',
						summary: '登入失敗',
						detail: `該帳號有人使用中，敬請聯繫系統管理員。該帳號IP位址：${res.body}`,
						life: 3000,
					});
				}
				))
			.subscribe();
	}
}
