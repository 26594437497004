import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs/Rx";
import { Store } from "@ngrx/store";
import * as fromUser from '@app/core/store/user';
import { User } from '@app/core/models';

@Component({
	selector: 'sa-navigation',
	templateUrl: './navigation.component.html'
})

export class NavigationComponent implements OnInit {
	currentUser$: Observable<User>;
	currentUser: User;

	// 頁籤顯示

	constructor(
		private store: Store<fromUser.UserState>) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
	}

	async ngOnInit() {
		this.currentUser$.subscribe((v) => {
			this.currentUser = v;
		});
	}
}
