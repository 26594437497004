import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';


@Injectable({
	providedIn: 'root'
})
export class EditInTimeService {

	constructor(private httpService: HttpClientService) { }

	public createEditInTime(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.EditInTimeUrl}/CreateEditInTime`,
			dataSrc
		);
	}

	public getEditInTimeList(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.EditInTimeUrl}/GetEditInTimeList`,
			dataSrc
		);
	}
}
