import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
@Component({
    selector: 'basic-datepicker',
    templateUrl: './basic-datepicker.component.html',
    styleUrls: ['./basic-datepicker.component.scss'],
})
export class BasicDatepickerComponent {
    @Input() ctrlName: any = new FormControl();
    @Input() form: FormGroup;
    @Input() showTime: boolean = false;
    @Input() invalidStyle: string;
    @Input() min: Date;
    @Input() max: Date;
    @Input() value: string = '';
    /** 說明文字 */
    @Input() placeholder: string = '請選擇時間區間';
    /** 控制使用者是否可選取時間 */
    @Input() disabled: boolean = false;

    timeForViewCtrlName: string;
    tw: any;

    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.tw = {
            firstDayOfWeek: 0,
            dayNames: ['日', '一', '二', '三', '四', '五', '六'],
            dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
            dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
            monthNames: [
                '一月',
                '二月',
                '三月',
                '四月',
                '五月',
                '六月',
                '七月',
                '八月',
                '九月',
                '十月',
                '十一月',
                '十二月',
            ],
            monthNamesShort: [
                '一月',
                '二月',
                '三月',
                '四月',
                '五月',
                '六月',
                '七月',
                '八月',
                '九月',
                '十月',
                '十一月',
                '十二月',
            ],
            today: '確認',
            clear: '清除',
            dateFormat: 'mm-dd-yy',
            weekHeader: 'Wk',
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ctrlName && changes.ctrlName.currentValue) {
            // 新增顯示用的時間
            this.timeForViewCtrlName = 'timeForView' + this.ctrlName;
            const time = this.form.value[this.ctrlName]
                ? this.form.value[this.ctrlName]
                : '';

            if (time) {
                this.form.addControl(
                    this.timeForViewCtrlName,
                    new FormControl(moment(time).format('YYYY-MM-DD'))
                );
            } else {
                this.form.addControl(
                    this.timeForViewCtrlName,
                    new FormControl()
                );
            }
        }

        if (changes.form && changes.form.currentValue) {
            // 新增顯示用的時間
            this.timeForViewCtrlName = 'timeForView' + this.ctrlName;
            const time = this.form.value[this.ctrlName]
                ? this.form.value[this.ctrlName]
                : '';


            if (time) {
                this.form.addControl(
                    this.timeForViewCtrlName,
                    new FormControl(moment(time).format('YYYY-MM-DD'))
                );
            } else {
                this.form.addControl(
                    this.timeForViewCtrlName,
                    new FormControl()
                );
            }
        }

        /** 設定最小值 */
        if (changes.min && changes.min.currentValue) {
            this.min = new Date(changes.min.currentValue);
        }

        /** 設定最大值 */
        if (changes.max && changes.max.currentValue) {
            this.max = new Date(changes.max.currentValue);
        }

        /** Disable模式 */
        if (changes.disable && changes.disable.currentValue) {
            this.disabled = changes.disable.currentValue;
        }

        /** value */
        if (changes.value && changes.value.currentValue) {
            const time = changes.value.currentValue

            let d = changes.value.currentValue
            let patchValue: any = {};
            patchValue[this.timeForViewCtrlName] = moment(d)
                .endOf('day')
                .format('YYYY-MM-DD');
            this.form.patchValue(patchValue);
        }

    }

    /**
     *  依據p-calendar改變時，回傳字串類型的日期格式
     * @param event 數值
     * @param formGroup 即將改變的formGroup
     * @param formControlName 即將改變的formControlName
     */
    onSelectMethodForCalendarFormat(
        event: any,
        formGroup: FormGroup,
        formControlName: string
    ) {

        if (formControlName.indexOf('End') !== -1 || formControlName.indexOf('To') !== -1) {
            /** 結束時間 */
            let d = new Date(Date.parse(event));
            let patchValue: any = {};
            patchValue[formControlName] = moment(d)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss');
            formGroup.patchValue(patchValue);
        } else if (formControlName.indexOf('Start') !== -1 || formControlName.indexOf('From') !== -1) {
            /** 起始時間 */
            let d = new Date(Date.parse(event));
            let patchValue: any = {};
            patchValue[formControlName] = moment(d)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss');
            formGroup.patchValue(patchValue);
        } else {
            let d = new Date(Date.parse(event));
            let patchValue: any = {};
            patchValue[formControlName] = moment(d).format(
                'YYYY-MM-DD HH:mm:ss'
            );
            formGroup.patchValue(patchValue);
        }
    }

    /** 清除時間 */
    clearTime() {
        this.form.controls[this.timeForViewCtrlName].setValue('');
    }
}
