import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';


@Injectable({
	providedIn: 'root'
})
export class MeetListService {
	constructor(private httpService: HttpClientService) { }

	// 取得所有備忘行事曆(可以藉由會議類型)
	public getMeetForCalendar(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.MeetUrl}/GetMeetForCalendar`,
			dataSrc
		);
	}

	// 刪除任務行事曆
	public deleteMeet(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.MeetUrl}/DeleteMeet`,
			dataSrc
		);
	}
}
