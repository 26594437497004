import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, throwError } from "rxjs";
import { delay, tap } from "rxjs/operators";
import { HttpClientService } from "@app/core/services/HttpClientService";
import { catchError, map } from "rxjs/internal/operators";
import { config } from "@app/core/app.config";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CaptchaService {
  constructor(private httpService: HttpClientService) { }

  //圖形驗證
  public getCaptcha(): string {
    return this.getBaseUrl() + `${config.AuthorizationUrl
      }/GetCaptcha` + "?t=" + new Date().getTime();
  }

  private getBaseUrl() {
    return environment.production
      ? document.getElementsByTagName("base")[0].href
      : "";
  }
}
