import { NgModule } from '@angular/core';
// 一般及第三方匯入
import { GeneralAndThirdPartyModule } from "@app/shared/general-and-third-party.module";
import { ConfirmDialogWithTextareaComponent } from './confirm-dialog-with-textarea.component';
@NgModule({
  declarations: [
    ConfirmDialogWithTextareaComponent
  ],
  imports: [
    GeneralAndThirdPartyModule
  ],
  exports: [
    ConfirmDialogWithTextareaComponent
  ],
  entryComponents: [
    ConfirmDialogWithTextareaComponent
  ]
})
export class ConfirmDialogWithTextareaModule { }