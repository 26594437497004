

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { I18nModule } from "../../i18n/i18n.module";
import { BigBreadcrumbsComponent } from "./big-breadcrumbs.component";
import { MinifyMenuComponent } from "./minify-menu.component";
import { NavigationComponent } from "./navigation.component";
import { SmartMenuDirective } from "./smart-menu.directive";
import { UserModule } from "../../user/user.module";
import { RouterModule } from "@angular/router";

// MATERIAL
// import {
//   MatCheckboxModule, MatCardModule, MatInputModule, MatGridListModule,
//   MatButtonModule, MatDialogModule, MatSortModule, MatTableModule, MatPaginatorModule,
//   MatIconModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatSidenavModule, MatTabsModule, MatSliderModule, MatSlideToggleModule, MatListModule, MatChipsModule, MatStepperModule, MatProgressSpinnerModule, MatBadgeModule
// } from '@angular/material';


import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Material Navigation
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
// Material Layout
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
// Material Buttons & Indicators
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
// Material Popups & Modals
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
// Material Data tables
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';


import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MenuItem } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';

import { CollapseMenuComponent } from "./collapse-menu/collapse-menu.component";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		I18nModule,
		UserModule,

		MatCheckboxModule, MatCardModule, MatInputModule, MatGridListModule,
		MatButtonModule, MatDialogModule, MatSortModule, MatTableModule, MatPaginatorModule,
		MatIconModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatSidenavModule, MatTabsModule, MatSliderModule, MatSlideToggleModule, MatListModule, MatChipsModule, MatStepperModule, MatProgressSpinnerModule, MatBadgeModule,

		FormsModule, ReactiveFormsModule,

		// PrimeNg
		BreadcrumbModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		DropdownModule,
		DialogModule,
		ToastModule,

	],
	declarations: [
		BigBreadcrumbsComponent,
		MinifyMenuComponent,
		NavigationComponent,
		SmartMenuDirective,
		CollapseMenuComponent,

	],
	exports: [
		BigBreadcrumbsComponent,
		MinifyMenuComponent,
		NavigationComponent,
		CollapseMenuComponent,
		SmartMenuDirective,

		// PrimeNg
		BreadcrumbModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		DropdownModule,
		DialogModule,
		ToastModule,
	],
	entryComponents: []
})
export class NavigationModule { }
