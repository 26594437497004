import { Component } from '@angular/core';
import { LayoutService } from '@app/core/services';
import * as fromLayout from '@app/core/store/layout';
import { Store } from '@ngrx/store';

@Component({
    selector: 'sa-minify-menu',
    template: `<span class="minifyme" (click)="toggle()" title="展開選單">
    <em class="fa fa-arrow-circle-left hit"></em>
</span>`,
})
export class MinifyMenuComponent {

    constructor(
        private store: Store<any>,
        private layoutService: LayoutService,
    ) {
    }

    toggle() {
        this.layoutService.onMinifyMenu();
        this.layoutService.onCollapseMenu(false)
    }

}
