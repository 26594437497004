import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Store } from "@ngrx/store";
import { User } from "@app/core/models";
import * as fromUser from "@app/core/store/user";

interface Options {
	headers: HttpHeaders;
}

@Injectable({
	providedIn: 'root'
})
export class YatingApiService {

	currentUser$: Observable<User>;
	currentUser: User;

	asrAPI = environment.production ? `https://${environment.asrUrl}` : 'YatingApi';

	options: Options;

	constructor(
		private store: Store<fromUser.UserState>,
		private http: HttpClient,
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
		this.currentUser$.subscribe(v => {
			this.currentUser = v;
			this.options = {
				headers: new HttpHeaders({ 'Authorization': `Bearer ${this.currentUser.YatingToken}` })
			}
		});
	}

	// 上傳音檔
	public uploadFile(dataSrc: any): Observable<any> {
		return this.http.post(`${this.asrAPI}/judicial/api/v1/upload/file`, dataSrc, this.options);
	}

	// 新增User
	public createUser(dataSrc: any): Observable<any> {
		return this.http.post(`${this.asrAPI}/judicial/api/v1/db/user/enterprise/create`, dataSrc, this.options);
	}

	// 檢查逐字稿狀態
	public voiceInfo(dataSrc: any): Observable<any> {
		return this.http.post(`${this.asrAPI}/judicial/api/v1/db/voice/info`, dataSrc, this.options);
	}
}
