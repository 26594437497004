import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicDatepickerComponent } from './basic-datepicker/basic-datepicker.component';

import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
    declarations: [BasicDatepickerComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        TableModule,
        CalendarModule,
        MultiSelectModule,
        DialogModule,
        ButtonModule,
        DropdownModule,
        ProgressBarModule,
        InputTextModule,
        InputTextareaModule,
        AccordionModule,
        RadioButtonModule,
        ConfirmDialogModule,
        DynamicDialogModule,
        InputNumberModule,
        SelectButtonModule,
    ],
    exports: [BasicDatepickerComponent],
})
export class DatepickerModule { }
