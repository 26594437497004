import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';


@Injectable({
	providedIn: 'root'
})
export class DocumentTrainingService {
	constructor(private httpService: HttpClientService) { }

	// 上傳文字模型
	public createLanguageModelData(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.LanguageModelUrl}/CreateLanguageModelData`,
			dataSrc
		);
	}

	// 上傳文字模型
	public createYatingLanguageModel(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.LanguageModelUrl}/CreateYatingLanguageModel`,
			dataSrc
		);
	}

	// 取得模型清單列表
	public getLanguageModelList(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.LanguageModelUrl}/GetLanguageModelList`,
			dataSrc
		);
	}

	// 刪除該帳號模型
	public deleteLanguageModel(dataSrc: any): Observable<any> {
		return this.httpService.httpDeleteWithBody(
			`${config.LanguageModelUrl}/DeleteLanguageModel`,
			dataSrc
		);
	}

	// 取得模型訓練狀態
	public getLanguageModelStatus(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.LanguageModelUrl}/GetLanguageModelStatus`,
			dataSrc
		);
	}

	// 下載文字模型
	public downloadLanguageModelData(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.LanguageModelUrl}/DownloadLanguageModelData`,
			dataSrc
		);
	}
}