import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';


@Injectable({
	providedIn: 'root'
})
export class MyTranscriptService {
	constructor(private httpService: HttpClientService) { }

	public getTranscriptList(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.TranscriptUrl}/GetTranscriptList`,
			dataSrc
		);
	}

	public deleteTranscript(dataSrc: any): Observable<any> {
		return this.httpService.httpDeleteWithBody(
			`${config.TranscriptUrl}/DeleteTranscript`,
			dataSrc
		);
	}
}
