import { StorageService } from './storage.service';
import { AuthTokenService } from './auth-token.service';

import { TokenInterceptor } from './token.interceptor';
import { MyHttpInterceptor } from './http.interceptor';
import { JsonApiService } from './json-api.service';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';
import { BodyService } from './body.service';
import { LayoutService } from './layout.service';

import { ApiService } from './api.service';
import { ToolboxService } from './toolbox.service';

import { SyscodeService } from './syscode.service';
import { ExcelService } from './excel.service';
import { BillboardService } from './billboard.service';
import { RoleGroupService } from './rolegroup.service';
import { CaptchaService } from './captcha.service';
import { MemoCalendarService } from './memo-calendar.service';
import { MeetManagementService } from './meet-management.service';
import { MeetListService } from './meet-list.service';
import { MyTranscriptService } from './my-transcript.service';
import { DocumentTrainingService } from './document-training.service';
import { TranscriptListService } from './transcript-list.service';
import { AuthorityManagementService } from './authority-management.service';
import { AuditManagementService } from './audit-management.service';
import { ProblemReportService } from './problem-report.service';
import { EditInTimeService } from './edit-in-time.service';
import { YatingApiService } from './yating-api.service';
import { VersionService } from './version.service';


// primeNg
import { MessageService, ConfirmationService } from 'primeng/api';

export const services = [
	StorageService,
	AuthTokenService,
	// AuthService,
	TokenInterceptor,
	MyHttpInterceptor,

	JsonApiService,
	UserService,
	NotificationService,
	BodyService,
	LayoutService,

	ApiService,
	ToolboxService,

	SyscodeService,
	ExcelService,
	BillboardService,
	RoleGroupService,
	CaptchaService,
	MemoCalendarService,
	MeetManagementService,
	MeetListService,
	MyTranscriptService,
	DocumentTrainingService,
	TranscriptListService,
	AuthorityManagementService,
	AuditManagementService,
	ProblemReportService,
	EditInTimeService,
	YatingApiService,
	VersionService,
	MessageService,
	ConfirmationService,
];

export * from './storage.service';
export * from './auth-token.service';
export * from './token.interceptor';
export * from './json-api.service';
export * from './user.service';
export * from './notification.service';
export * from './body.service';
export * from './layout.service';
export * from './api.service';
export * from './toolbox.service';
export * from './syscode.service';
export * from './excel.service';
export * from './billboard.service';
export * from './rolegroup.service';
export * from './captcha.service';
export * from './memo-calendar.service';
export * from './meet-management.service';
export * from './meet-list.service';
export * from './my-transcript.service';
export * from './document-training.service';
export * from './transcript-list.service';
export * from './authority-management.service';
export * from './audit-management.service';
export * from './problem-report.service';
export * from './edit-in-time.service';
export * from './yating-api.service';
export * from './version.service';

export * from 'primeng/api';
