import { Component } from '@angular/core';
import { SyscodeService } from '@app/core/services';
import { PrimeNGConfig } from 'primeng/api';

@Component({
	selector: 'app-root',
	template: '<router-outlet></router-outlet>',
})
export class AppComponent {
	title = 'sa';

	constructor(
		public syscodeService: SyscodeService,
		private primengConfig: PrimeNGConfig
	) { }

	ngOnInit() {
		this.primengConfig.ripple = true;
		$('html, body').animate({ scrollTop: 0 }, 100);
		this.primengConfig.setTranslation({
			accept: '確認',
			reject: '取消',
			dayNames: ['日', '一', '二', '三', '四', '五', '六'],
			dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
			dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
			monthNames: [
				'一月',
				'二月',
				'三月',
				'四月',
				'五月',
				'六月',
				'七月',
				'八月',
				'九月',
				'十月',
				'十一月',
				'十二月',
			],
			monthNamesShort: [
				'一月',
				'二月',
				'三月',
				'四月',
				'五月',
				'六月',
				'七月',
				'八月',
				'九月',
				'十月',
				'十一月',
				'十二月',
			],
			today: '確認',
			clear: '清除',
			weekHeader: 'Wk',
			// 參數
			// startsWith: 'Starts with',
			// contains: 'Contains',
			// notContains: 'Not contains',
			// endsWith: 'Ends with',
			// equals: 'Equals',
			// notEquals: 'Not equals',
			// noFilter: 'No Filter',
			// lt: 'Less than',
			// lte: 'Less than or equal to',
			// gt: 'Greater than',
			// gte: 'Greater than or equal to',
			// is: 'Is',
			// isNot: 'Is not',
			// before: 'Before',
			// after: 'After',
			// dateIs: 'Date is',
			// dateIsNot: 'Date is not',
			// dateBefore: 'Date is before',
			// dateAfter: 'Date is after',
			// clear: 'Clear',
			// apply: 'Apply',
			// matchAll: 'Match All',
			// matchAny: 'Match Any',
			// addRule: 'Add Rule',
			// removeRule: 'Remove Rule',
			// accept: 'Yes',
			// reject: 'No',
			// choose: 'Choose',
			// upload: 'Upload',
			// cancel: 'Cancel',
			// dayNames: [
			// 	'Sunday',
			// 	'Monday',
			// 	'Tuesday',
			// 	'Wednesday',
			// 	'Thursday',
			// 	'Friday',
			// 	'Saturday',
			// ],
			// dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			// dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
			// monthNames: [
			// 	'January',
			// 	'February',
			// 	'March',
			// 	'April',
			// 	'May',
			// 	'June',
			// 	'July',
			// 	'August',
			// 	'September',
			// 	'October',
			// 	'November',
			// 	'December',
			// ],
			// monthNamesShort: [
			// 	'Jan',
			// 	'Feb',
			// 	'Mar',
			// 	'Apr',
			// 	'May',
			// 	'Jun',
			// 	'Jul',
			// 	'Aug',
			// 	'Sep',
			// 	'Oct',
			// 	'Nov',
			// 	'Dec',
			// ],
			// dateFormat: 'mm/dd/yy',
			// firstDayOfWeek: 0,
			// today: 'Today',
			// weekHeader: 'Wk',
			// weak: 'Weak',
			// medium: 'Medium',
			// strong: 'Strong',
			// passwordPrompt: 'Enter a password',
			// emptyMessage: 'No results found',
			// emptyFilterMessage: 'No results found',
		});
	}
}
