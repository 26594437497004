import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { NotificationService, MessageService } from '@app/core/services';
import { User } from '@app/core/models';
import { Observable } from 'rxjs/Rx';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';
import { of, zip } from 'rxjs/index';
import { catchError, map } from 'rxjs/internal/operators';

@Injectable({
	providedIn: 'root',
})

// @Injectable()
export class UrlGuard implements CanActivate, CanActivateChild {
	currentUser$: Observable<User>;

	constructor(
		private store: Store<fromUser.UserState>,
		private notificationService: NotificationService,
		private router: Router,
		private messageService: MessageService
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
	}

	// 用於防止未經授權的用戶訪問某些路由
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		// console.log('canActivate:', route);
		let url: string = state.url;
		let page: string = route.data.pageTitle;

		return this.checkUrlPermission(url, page);
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		// console.log('canActivateChild:', route, state);
		let url: string = state.url;
		let page: string = route.data.pageTitle;

		return this.checkUrlPermission(url, page);
	}

	checkUrlPermission(url: string, page: string): Observable<boolean> {
		return zip(this.currentUser$).pipe(
			map(([currentUser]) => {
				if (
					currentUser.Permission.filter((permission) => {
						let regexpUrl = new RegExp(permission);
						// console.log(regexpUrl);
						return regexpUrl.test(url);
					}).length > 0 ||
					currentUser.Role === 'Admin'
				) {
					return true;
				} else {
					// this.router.navigate(["error404"]);
					console.log('this.messageService:', this.messageService);
					this.messageService.add({
						severity: 'error',
						summary: '權限問題',
						detail: '無權限前往該頁',
						life: 3000,
					});
					// this.notificationService.smallBox({
					// 	title: '無權限前往該頁',
					// 	color: 'var(--color-red)',
					// 	iconSmall: 'fa fa-warning bounce animated',
					// 	timeout: 3000,
					// });
					return false;
				}
			}),
			catchError((err) => {
				// console.log("發生錯誤, ", err);
				// this.router.navigate(["error404"]);
				return of(false);
			})
		);
	}
}
