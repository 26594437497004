import { Injectable, Injector } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {

	constructor(
		private injector: Injector
	) { }

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let userInfo: any = localStorage.getItem('user');
		let userId;
		if (userInfo) {
			userInfo = JSON.parse(userInfo);
			if (userInfo.isLogin) {
				userId = userInfo.currentUser.UserId;
			}
		}

		const customRequest = userId
			? request.clone({
				headers: request.headers
					.set('ClientId', userId)
				// .set('ApiKey', '5a0e837088b649648b14c8396274348c'),
			})
			: request;

		return next.handle(customRequest);
	}
}
