import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';

@Injectable()
export class UserService {
	constructor(
		private httpService: HttpClientService
	) { }

	/** 使用者-使用者登入 */
	userLogin(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UserLogin`,
			dataSrc
		);
	}

	/** 使用者-使用者登出 */
	userLogout(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UserLogout`,
			dataSrc
		);
	}

	/** 帳號權限管理-修改密碼 */
	changePassword(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/ChangePassword`,
			dataSrc
		);
	}

	/** 單一登入 */
	singleSignOn(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.SingleUrl}/SingleSignIn?Ticket=${dataSrc}`,
			''
		);
	}

	/** 取得使用者資訊 */
	public getUserInfoList(): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/GetUserInfoList`,
			null
		);
	}

	/** 帳號權限管理-帳號新增 */
	public createUserInfo(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/CreateUserInfo`,
			dataSrc
		);
	}

	/** 更新使用者 */
	public updateUserInfo(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UpdateUserInfo`,
			dataSrc
		);
	}

	/** 更新在線資訊 */
	public updateUserLogin(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UpdateUserLogin`,
			dataSrc
		);
	}

	/** 檢查帳號登入狀態 */
	public checkUserLogin(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/CheckUserLogin`,
			dataSrc
		);
	}


	/** 圖形驗證 */
	public getCaptcha(): string {
		return `${config.AuthorizationUrl}` + '?t=' + new Date().getTime();
	}
}
