import { Injectable } from '@angular/core';
import { ResponseObj, Role } from '@app/core/models';
import { Observable } from 'rxjs';
import { config } from '@app/core/app.config';
import { environment } from '../../../environments/environment';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: 'root',
})
export class RoleGroupService {
	constructor(
		private http: HttpClient,
		private httpService: HttpClientService
	) { }

	// 取得角色權限
	public getRoleGroupList(): Observable<any> {
		return this.httpService.httpPost(
			`${config.RoleGroupUrl}/GetRoleGroupList`,
			''
		);
	}

	// 新增角色權限
	public createRoleGroup(dataSrc: Role): Observable<any> {
		return this.httpService.httpPost(
			`${config.RoleGroupUrl}/CreateRoleGroup`,
			dataSrc
		);
	}

	// 修改角色權限功能
	public updateRoleGroupPermission(dataSrc: Role): Observable<any> {
		return this.httpService.httpPost(
			`${config.RoleGroupUrl}/UpdateRoleGroupPermission`,
			dataSrc
		);
	}

	// 修改角色權限使用者
	public updateRoleGroupUser(dataSrc: Role): Observable<any> {
		return this.httpService.httpPost(
			`${config.RoleGroupUrl}/UpdateRoleGroupUser`,
			dataSrc
		);
	}

	// 刪除角色
	public deleteRoleGroup(dataSrc: any): Observable<any> {
		return this.http.delete(
			`${config.RoleGroupUrl}/DeleteRoleGroup`, { body: dataSrc }
		);
	}

	// 取得各權限總數
	public getYatingRoleCount(): Observable<ResponseObj> {
		return this.httpService.httpPost(
			`${config.RoleGroupUrl}/GetYatingRoleCount`,
			{}
		);
	}
}
