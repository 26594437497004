import {
	Component,
	OnInit,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { ProblemReportService } from '@app/core/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';
import { User } from '@app/core/models';
import { Observable } from 'rxjs/Rx';
import { LayoutService } from '@app/core/services/layout.service';

/*-- Prime NG --*/
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { finalize } from "rxjs/operators";

@Component({
	selector: 'sa-big-breadcrumbs',
	template: `
		<!-- 提示條  Start ************************************-->
		<p-toast></p-toast>
		<!-- 提示條  END ************************************-->

		<!-- 麵包屑  Start ************************************-->
		<div class="w-100 d-flex justify-content-between align-items-baseline">
			<!-- 麵包 -->
			<p-breadcrumb [model]="model" [home]="home"></p-breadcrumb>

			<!-- 按鈕：問題回報 -->
			<button pButton pRipple label="問題回報"
				type="button"
				icon="fa fa-fw fa-wrench"
				class="p-button-raised p-button-text color-warning"
				(click)="Report()">
			</button>
		</div>
		<!-- 麵包屑  END ************************************-->

		<!-- Dialog:問題回報  Start ************************************-->
		<p-dialog
			header=""
			[(visible)]="reportFormModal"
			[style]="{ width: '600px' }"
			[modal]="true"
			[dismissableMask]="true"
			[draggable]="false"
			[showHeader]="true"
			[closable]="true">

			<ng-template pTemplate="header">
				<span class="p-dialog-title">問題回報</span>
			</ng-template>

			<ng-template pTemplate="content">
				<div class="row">
					<form [formGroup]="reportForm" class="col-12 font-size-20">
						<div class="row">
							<!-- 目前功能 -->
							<div class="col-12 d-flex align-items-start my-2">
								<label class="col-sm-3 col-form-label">
									功能頁面：
								</label>
								<div class="col-sm-9">
									<input pInputText
										type="text"
										class="form-control"
										formControlName="Menu"
										[attr.disabled]='true'/>
								</div>
							</div>

							<!-- 回報類型 -->
							<div class="col-12 d-flex align-items-start my-2">
								<label class="col-sm-3 col-form-label">
									<code>*</code>類型：
								</label>
								<div class="col-sm-9">
									<p-dropdown
										formControlName="ProblemType"
										optionLabel="name"
										optionValue="code"
										[filter]="true"
										[showClear]="true"
										scrollHeight="200px"
										placeholder="請選擇回報類型"
										[options]="[
											{
												name: '問題',
												code: '1'
											},
											{
												name: '需求',
												code: '2'
											}
										]"
										[emptyFilterMessage]="'查無符合的項目'">
									</p-dropdown>
								</div>
							</div>

							<!-- 主旨 -->
							<div class="col-12 d-flex align-items-start my-2">
								<label class="col-sm-3 col-form-label">
									<code>*</code>問題主旨：
								</label>
								<div class="col-sm-9">
									<input pInputText
										type="text"
										class="form-control"
										placeholder="請輸入主旨"
										formControlName="Subject"
										maxlength="60"/>
								</div>
							</div>

							<!-- 內容 -->
							<div class="col-12 d-flex align-items-start my-2">
								<label for="" class="col-sm-3 col-form-label">
									<code>*</code>問題內容：
								</label>
								<div class="col-sm-9">
									<textarea pInputTextarea
										formControlName="ReportContent"
										[rows]="5"
										maxlength="150"
										placeholder="請輸入問題內容(最多150字)"
										autoResize="autoResize">
										</textarea>
								</div>
							</div>
						</div>
					</form>
				</div>
			</ng-template>

			<ng-template pTemplate="footer">
				<div class="col-12">
					<div class="card-footer p-0">
						<div class="d-flex justify-content-end align-items-start">
							<button pButton
								icon="pi pi-check"
								type="button"
								label="送出"
								class="p-button-primary mr-3"
								[disabled]="reportForm.invalid"
								(click)="reportQues(reportForm)"></button>
							<button pButton
								icon="pi pi-times"
								type="button"
								label="取消"
								class="p-button-danger mr-0"
								(click)="reportFormModal = false"></button>
						</div>
					</div>
				</div>
			</ng-template>
		</p-dialog>
		<!-- Dialog:問題回報  End ************************************-->
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [DialogService, MessageService], // 問題回報 primeng Dialoag
})
export class BigBreadcrumbsComponent implements OnInit {
	currentUser$: Observable<User>;
	currentUser: User;

	@Input() public model;
	@Input() public home;

	ref: DynamicDialogRef;

	getPageTitle: string;

	// 問題回報
	reportFormModal = false;
	reportForm = this.fb.group({
		Menu: ['', Validators.required], // 當前頁面名稱
		Subject: ['', Validators.required], // 主旨
		ProblemType: ['', Validators.required], // 問題類型
		ReportContent: ['', Validators.required], // 內容
		CreateUserId: ['']
	});

	constructor(
		private store: Store<fromUser.UserState>,
		private fb: FormBuilder,
		public messageService: MessageService,
		public dialogService: DialogService,
		public layoutService: LayoutService,
		public problemReportService: ProblemReportService
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
	}

	ngOnInit() {
		this.currentUser$.subscribe(v => {
			this.currentUser = v;
		});

		// 取得當前頁面 Title
		this.getPageTitle = this.model ? this.model[this.model.length - 1].label : this.home.label;
	}

	Report() {
		this.reportForm.reset();
		this.reportForm.patchValue({
			Menu: this.getPageTitle,
		});
		this.reportFormModal = true;
	}

	// 問題回報
	reportQues(form: FormGroup) {
		this.layoutService.pageLoading = true;
		form.controls['CreateUserId'].setValue(this.currentUser.UserId);
		this.problemReportService.createUserReport(form.value)
			.pipe(finalize(() => {
				this.layoutService.pageLoading = false;
				this.reportFormModal = false;
			}))
			.subscribe((data: any) => {
				if (data.isSuccess) {
					this.messageService.add({
						severity: 'success',
						summary: '問題回報 成功',
						detail: '',
						life: 3000,
					});
				} else {
					this.messageService.add({
						severity: 'error',
						summary: '問題回報 失敗',
						detail: '請聯繫管理員',
						life: 3000,
					});
				}
			});
	}
}
