import { NgModule, ModuleWithProviders } from '@angular/core';

// 一般及第三方匯入
import { GeneralAndThirdPartyModule } from '@app/shared/general-and-third-party.module';

// layout/I18n...
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { UserModule } from '@app/shared/user/user.module';
import { UtilsModule } from '@app/shared/utils/utils.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { InlineGraphsModule } from '@app/shared/graphs/inline/inline-graphs.module';

import { SmartadminLayoutModule } from '@app/shared/layout';
import { SmartadminWidgetsModule } from '@app/shared/widgets/smartadmin-widgets.module';
import { SmartadminFormsLiteModule } from '@app/shared/forms/smartadmin-forms-lite.module';
import { SmartProgressbarModule } from '@app/shared/ui/smart-progressbar/smart-progressbar.module';

// custom shared Module
import { DatepickerModule } from '@app/shared/datepicker/datepicker.module';
import { ConfirmDialogWithTextareaModule } from '@app/shared/confirm-dialog-with-textarea/confirm-dialog-with-textarea.module';

@NgModule({
	imports: [
		// 一般及第三方匯入
		GeneralAndThirdPartyModule,

		// layout/I18n...
		I18nModule,
		UserModule,
		UtilsModule,
		PipesModule,
		InlineGraphsModule,

		SmartadminLayoutModule,
		SmartadminWidgetsModule,
		SmartadminFormsLiteModule,
		SmartProgressbarModule,

		// custom shared Module
		DatepickerModule,
		ConfirmDialogWithTextareaModule,
	],
	exports: [
		// 一般及第三方匯入
		GeneralAndThirdPartyModule,

		// layout/I18n...
		I18nModule,
		UserModule,
		UtilsModule,
		PipesModule,
		InlineGraphsModule,

		SmartadminLayoutModule,
		SmartadminWidgetsModule,
		SmartadminFormsLiteModule,
		SmartProgressbarModule,

		// custom shared Module
		DatepickerModule,
		ConfirmDialogWithTextareaModule,
	],
})
export class SharedModule { }
