import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sysCodeGroupFilter'
})
export class SysCodeGroupPipe implements PipeTransform {

  transform(value: string, exponent: String[]): string {
    // console.log(value , exponent)
    let data: String[] = exponent.filter((item: String) => item['Code'] == value);
    return data.length > 0 ? data[0]['Desc'] : "";
  }
}